const data = [
        {
            id: 1,
            title: "RAC & VRF solutions",
            image: "about2.jpg",
            content: "Welcome to the Airtec Marketing Website!",
            brand: "Mitsubishi Heavy",
            brandimg: "mitsubishi.png",
            cardtitle: "Ashok Raj Resort and Farms, Indore",
            cardsubheader: "332 HP Project",
            cardcontent: "This project was completed in 2022. It is an exclusive marriage garden with banquet hall and rooms. The main requirement was to ensure a cool environement irrespective of occupancy rate.",
            cardexpandcontent: "O-General was the brand used for this project. As it is a large area, 332 HP was used and capacity was distributed in a structured way to ensure stable ambient temprature.",
            projectImage: [
                "ashokraj.webp",
                "ashokraj1.webp",
                "ashokraj2.jpg",
                "ashokraj3.jpg",
                "ashokraj4.jpg"
            ]
        },
        {
            id: 2,
            title: "Selling Air conditioners",
            image: "about1.jpeg",
            content: "Learn more about Airtec and our services.",
            brand: "O General",
            brandimg: "ogeneral.png",
            cardtitle: "SRD Marriage Garden, Indore",
            cardsubheader: "114 HP with AHU and VRF Project",
            cardcontent: "This project is expected to be completed by Dec 2024. It is a marriage garden with a banquet hall and rooms. Here, also the main requirement is to ensure a cool environement irrespective of occupancy rate.",
            cardexpandcontent: "O-General VRF and AHU were used for this project. The project is in the final stages of installation of outdoor units.",
            projectImage: [
                "srd.webp",
                "srd1.webp",
                "srd2.webp"
            ]
        },
        {
            id: 3,
            title: "Servicing & repairs",
            image: "about3.jpeg",
            content: "Check out our latest products.",
            brand: "Panasonic",
            brandimg: "panasonic.png",
            cardtitle: "Palm Aura Resort, Indore",
            cardsubheader: "180 HP VRF Project",
            cardcontent: "This is an ongoing project since 2021 and expected to be completed by March 2025. It is a resort with rooms, exculsive cottages offering mesmerising views of the hills.",
            cardexpandcontent: "O-General VRF were used for this project. The project is still in the piping installation phase and soon will begin with commissioning on indoor AC units.",
            projectImage: [
                "palmaura.webp"
            ]
        },
        {
            id: 4,
            title: "AMC for HVAC projects",
            image: "about4.jpeg",
            content: "Get in touch with us.",
            brand: "Carrier",
            brandimg: "carrier.jpeg",
            cardtitle: "Saifee Nagar Masjid, Indore",
            cardsubheader: "300 TR Ductable Project",
            cardcontent: "This project was completed in 2017 and was a challenging project as we had to ensure minimal damage and rework to the existing structure and detailed interiors of the masjid.",
            cardexpandcontent: "Carrier machines were used for this project. For minimal rework we took maximum piping from outside the masjid and concealed it in the walls.",
            projectImage: [
                "saifeemasjid.jpg",
                "saifeemasjid1.jpg",
                "saifeemasjid2.jpg"
            ]
        },
        {
            id: 5,
            title: null,
            image: null,
            content: null,
            brand: "Voltas",
            brandimg: "voltas.jpeg",
            cardtitle: "Masakin Masjid, Bijalpur, Indore",
            cardsubheader: "90 TR Ductable Project",
            cardcontent: "This project was completed in 2022. It was a timebound project with a deadline for completion of 15 days. Despite challenges, the project was completed on time.",
            cardexpandcontent: "O-General ductables were used in this project. We ensured enough tonnage to cool the masjid in peak summers.",
            projectImage: [
                "masakin.jpg",
                "masakin1.jpg",
                "masakin2.jpg",
                "masakin3.jpg"
            ]
        },
        {
            id: 6,
            title: null,
            image: null,
            content: null,
            brand: "Daikin",
            brandimg: "daikin.png",
            cardtitle: "Noorani Nagar Masjid, Indore",
            cardsubheader: "42 TR Project",
            cardcontent: "This project was completed in 2024 within 11 days. Here we also had to ensure minimal damage to the existing structure and interiors of the masjid.",
            cardexpandcontent: "O-General ductables were used in this project. We executed the work in night shifts and carefully planned the capacity to cool masjid in minimal tonnage.",
            projectImage: [
                "noorani.jpeg"
            ]
        },
        {
            id: 7,
            title: null,
            image: null,
            content: null,
            brand: null,
            brandimg: null,
            cardtitle: "Mr. Mustafa Lohawala, Dewas",
            cardsubheader: "36 HP VRF Project",
            cardcontent: "This residential project was completed in 2020. It was our first VRF project in Dewas and client had huge expectations from us. We delivered the project on time and with utmost satisfaction.",
            cardexpandcontent: "O-General VRF were used in this project. It was a great experience for us and we are proud to have delivered the project on time.",
            projectImage: [
                "dewas.webp",
                "dewas1.jpg",
                "dewas2.jpg"
            ]
        },
        {
            id: 8,
            title: null,
            image: null,
            content: null,
            brand: null,
            brandimg: null,
            cardtitle: "Mr. Jitu Nagpal Residence, Indore",
            cardsubheader: "34 HP VRF roject",
            cardcontent: "This impressive project was completed by Airtec Marketing in 2022. It was a residential project and a challenging one as the client had a very specific requirement.",
            cardexpandcontent: "The client wanted a specific brand and model of ACs. We used O-General VRF for this project and the client was very satisfied with the results.",
            projectImage: [
                "nagpal.webp"
            ]
        },
        {
            id: 9,
            title: null,
            image: null,
            content: null,
            brand: null,
            brandimg: null,
            cardtitle: "Cafe Terezza, Indore",
            cardsubheader: "10 TR Ductable Project",
            cardcontent: "This impressive project was completed by Airtec Marketing in 2020. The project was completed in 6 months and the client was very satisfied with results.",
            cardexpandcontent: "O-General VRF units were used for this project.",
            projectImage: [
                "terezza.webp",
                "terezza1.jpg"
            ]
        },
        {
            id: 10,
            title: null,
            image: null,
            content: null,
            brand: null,
            brandimg: null,
            cardtitle: "Ashok Raj Resorts Reception area, Indore",
            cardsubheader: "24 HP VRF Project",
            cardcontent: "This project was completed by Airtec Marketing in 2024 and was a extension of ashok raj resorts project. The project was completed in 15 days and the client was very satisfied with results.",
            cardexpandcontent: "Voltas VRF units were used for this project.",
            projectImage: [
                "ashokreception.webp",
                "ashokreception1.webp"
            ]
        }
];

export default data;
