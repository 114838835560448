import React from 'react'

function AboutHeader() {
  return (
      <div className='about-section'>
      <p><b>Airtec Marketing was founded in 1997 by Mr. Khuzema Saifee</b> with a vision to provide quality services and products to the customers.
        In the span of 27 years, we have successfully completed numerous commercial, residential projects in Madhya Pradesh and have a huge base of satisfied customers.
        We are proud to be associated with renowned brands namely Mitsubishi Heavy, O General, and other brands. We ensure best workmanship by using high quality materials and equipments in our projects.
        Our team of experienced technicians is always ready to provide you with the best service and support.
        <br/>We are a one-stop solution for all your air conditioning needs.
      </p>
    </div>
  )
}

export default AboutHeader
