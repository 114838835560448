import Navbar from './components/Navbar';
import "./style.css";
import {BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About/AboutServices.js';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import data from './Data.js';
import AboutHeader from './pages/About/About.js';
import AboutBrands from './pages/About/AboutBrands.js';

function App() {
const aboutData= data.map((data) => {
  return (
    <About 
    key={data.id}
    title={data.title}
    image={data.image}
    content={data.content}
    />
  )
})

const aboutBrands= data.map((data) => {
  return (
    <AboutBrands 
    key={data.id}
    img={data.brandimg}
    brand={data.brand}
    />
  )
})

const projects= data.map((data) => {
  return (
    <Projects 
    key={data.id}
    cardtitle={data.cardtitle}
    cardsubheader={data.cardsubheader}
    cardcontent={data.cardcontent}
    cardexpandcontent={data.cardexpandcontent}
    projectImage={data.projectImage}
    />
  )
})

return (

<div className='App'>
<Router>
<Navbar/>
<Routes>
  <Route path='/' exact element={<Home/>}/>
  <Route path='/about' element={
    <div className='about'>
      <AboutHeader/>
      <h2 style={{textAlign: 'center', marginTop: '1rem'}}>Our Services</h2>
      <div className='about-services'>
      {aboutData}
      </div>
      <h2 style={{textAlign: 'center', marginTop: '1.5rem'}}>Brands</h2>
      <div className='brands'>
      {aboutBrands}
      </div>
    </div>
}/>
<Route path='/projects' element={
    <div style={{backgroundColor: 'rgb(178, 172, 159)'}} className='projects-main'>
    {projects}
    </div>
}/>
<Route path='/contact' element={<Contact/>}/>
  </Routes>
  </Router>
  <Footer />
</div>
)
}
export default App;
