import React from 'react'

function AboutBrands(props) {
  if (props.img) {
  return (
    <div className='about-brands'>
        <img src={require(`../../assets/${props.img}`)} alt="img" loading='lazy'/>
    </div>
  )
} else {
  return null
}
}

export default AboutBrands
