import React from 'react';
import homeacImage from '../assets/homenew.jpg';
import About from './About/AboutServices.js';
import data from '../Data.js';
import AboutBrands from './About/AboutBrands.js';
import { IoBusiness } from "react-icons/io5";
import { MdContactPhone } from "react-icons/md";
import { Link } from 'react-router-dom';

function Home() {
  const aboutData= data.map((data) => {
    return (
      <About 
      key={data.id}
      title={data.title}
      image={data.image}
      content={data.content}
      />
    )
  })
  const aboutBrands= data.map((data) => {
    return (
      <AboutBrands 
      key={data.id}
      img={data.brandimg}
      brand={data.brand}
      />
    )
  })
  return (
    <div>
      <img className="homepage-image" src={homeacImage} alt="Home" style={{width: '100%', height: '70vh'}}/>
      <p className="image-text">Your cooling partner since 1997</p>
      <div className='hometext'>
      <p>Welcome to <b>Airtec Marketing</b>, your trusted air conditioning service provider. 
      With 27 years of experience, we specialize in selling air conditioning solutions, delivering high-quality installation, services and maintenance tailored to meet your needs. 
      We pride ourselves on our commitment to customer satisfaction, using the latest technology and techniques to provide 
      efficient, reliable solutions. Choose Airtec Marketing for all your HVAC needs where your comfort is our mission!</p>
      </div>
      <h2 style={{textAlign: 'center', padding: '1rem'}}>Our Services</h2>
      <div className='home-services'>
      {aboutData}
      </div>
      <h2 style={{textAlign: 'center', marginTop: '1.5rem'}}>Brands</h2>
      <div className='brands'>
      {aboutBrands}
      </div>
      <div className='home-button'>
      <Link
      role='button'
      to="/contact"
      >
      <button className='home-button-contact'>
        <MdContactPhone/>
        <p style={{textDecoration:'underline'}}>Contact Us</p>
      </button>
      </Link>

      <Link
      role='button'
      to="/projects"
      >
      <button className='home-button-projects'>
      < IoBusiness/>
      <p style={{textDecoration:'underline'}}>Our Projects</p>
      </button>
      </Link>
      </div>
      </div>
  );
}

export default Home;