import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';

const Navbar = () => {
    const [showMenu, setShowMenu] = React.useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
//HandleToggle function for opening or closing list
    const handleToggle = () => {
        setShowMenu(!showMenu);
    };
//Only show close menu if isMobile is true
    const closeMenu = () => {
        if(isMobile) {
        setShowMenu(false);
        }
    };
//Create navbar menu list
    const renderNavLinks = () => {
        const listClassName = isMobile ? "nav-list" : "nav-list-web";
        const linkClassName = "nav-link";
        return (
          <ul className={listClassName}>
            <li>
              <NavLink to="/" className={linkClassName} onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={linkClassName}
                onClick={closeMenu}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className={linkClassName}
                onClick={closeMenu}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={linkClassName}
                onClick={closeMenu} 
              >
                Contact
              </NavLink>
            </li>
          </ul>
        );
      };
    return (
        <header className='header'>
        <nav className='navbar'>
            <NavLink to="/" className='nav-logo'>Airtec Marketing</NavLink>
          {isMobile && !showMenu &&(
            <button type="button" className="nav-toggle" id="nav-toggle" onClick={handleToggle}>
            <IoMenu />
          </button>)}

        {isMobile && showMenu && (
          <div className="nav-menu" id="nav-menu">
            <button  type="button" className="nav-close" onClick={handleToggle}>
              <IoClose />
            </button>
            {renderNavLinks()}
          </div>
        )}
        {!isMobile && renderNavLinks()}
        </nav>
        </header>
    );
};

export default Navbar;