import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { EffectCoverflow } from 'swiper/modules';
import { Navigation } from 'swiper/modules';


export default function Projects(props) {
  const [expanded, setExpanded] = React.useState(false);
if (props.cardtitle && props.cardsubheader && props.cardcontent) {
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })
  (({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    variants: [
      {
        props: ({ expand }) => !expand,
        style: {
          transform: 'rotate(0deg)',
        },
      },
      {
        props: ({ expand }) => !!expand,
        style: {
          transform: 'rotate(180deg)',
        },
      },
    ],
  }));
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div className='projects'>
    <Card className='projects-card'>
      <CardHeader
      sx={{ height: '5.5rem' }}
      titleTypographyProps={{
        fontSize: 14,
        fontWeight: 'bolder',
      }}
      subheaderTypographyProps={{
        fontSize: 11,
        fontWeight: 'bold'
      }}
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="project">
            <AssignmentIcon />
          </Avatar>
        }
        title={props.cardtitle}
        subheader={props.cardsubheader}
      />
      <CardMedia>
          <Swiper
            modules={[Navigation, EffectCoverflow]}
            effect='coverflow'
            watchOverflow={true}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={1}
            navigation={true}
            pagination={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
          >
            {props.projectImage.map((image, index) => (
              <SwiperSlide key={index} className='swiper-slider'>
                <img
                src={require(`../assets/${image}`)} 
                alt={`Im${index}`}
                className='swiper-image'
                loading='lazy'
                />
              </SwiperSlide>
            ))}
          </Swiper>
          </CardMedia>
      <CardContent>
        <Typography variant="body2" sx={{ fontSize: '0.75rem', marginTop: '0.1rem', fontWeight: 'bold' }}>
          {props.cardcontent}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ marginTop: '0', marginBottom: '0', marginLeft: 'auto', marginRight: 'auto', padding: '0'}}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography sx={{ fontSize: '0.75rem', margin: '0.2rem', fontWeight: 'bold'}}>Additional Information:</Typography>
          <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold'}}>
            {props.cardexpandcontent}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </div>
);
} else {
  return null;
}
}

