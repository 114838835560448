import * as React from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

function About(props) {
  if(props.title && props.image) {
  return (
    <Card sx={{maxWidth: 300}} className='about-services-card'>
      <CardMedia>
        <img src={require(`../../assets/${props.image}`)} alt='Services' className='about-services-image'/>
      </CardMedia>
      <CardContent sx={{textAlign: 'center', fontStyle: 'italic'}}>
        <h5 className='about-services-card-title'>{props.title}</h5>
        </CardContent>
        </Card>
  )
} else {
  return null
}
}

export default About

