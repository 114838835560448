import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <footer className="footer">© 2024 Airtec Marketing. All Rights Reserved. Developed by Murtaza Saifee.</footer>
    </div>
  )
}

export default Footer
