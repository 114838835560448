import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import emailjs from '@emailjs/browser';
import { GoogleMap, useJsApiLoader, MarkerF} from '@react-google-maps/api';

export default function Contact() {
  const [map, setMap] = React.useState(null)
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    services: '',
    comments: ''
  })
//Create an array of service list
  const serviceOptions = [
    "Commercial Project Inquiry",
    "Residential Project Inquiry",
    "Air Conditioning Consultancy",
    "Buying a new AC",
    "Air conditioner servicing",
    "Other services"]
//Display services list
  const serviceOptionsValue = serviceOptions.map((serviceOption, key) => (
      <option value={serviceOption} key={key}>
        {serviceOption}
      </option>
    ));
  //When someone types in the form, this function is called
  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
}
  async function handleSubmit(e) {
    e.preventDefault()
    //Decalre the emailjs variables
    const templateParams = {
      from_name: formData.firstName + ' ' + formData.lastName,
      to_name: 'Airtec Marketing',
      email: formData.email,
      phone: formData.phone,
      services: formData.services,
      comments: formData.comments
    };
    //Send the email
    emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        alert('Your message has been sent. We will get back to you soon.');
      }, (error) => {
        console.log(error.text);
        alert('An error occurred, Please try again.');
      });
    //Reset the form
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      services: '',
      comments: ''
    });
    };
//Style the map
    const containerStyle = {
      width: '100%',
      height: '150px'
    }; 
    const center = React.useMemo(() => ({
      lat: 22.723792182972772,
      lng: 75.88185394540653
    }), []);
//Initialize the map
      const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      })
//Every time the map is loaded, this function is called and will reload the map if the center changes      
      const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        new window.google.maps.LatLngBounds(center);
        setMap(map);
      }, [center])
//Every time the map is unmounted, this function is called and will set the map to null
      const onUnmount = React.useCallback(function callback() {
        setMap(null)
      }, [])
  return (
    <div className='contact'>
    <div className='contact-container'>
        <h3 style={{fontWeight: 'bold', textAlign: 'center'}}>How can we serve you?</h3>
        <h6>Please fill out the form below</h6>
        <form onSubmit={handleSubmit} className='contactForm'>
            <label className='formFirstName' htmlFor="firstName">First Name
            <input
                id="firstName"
                type="text"
                placeholder="First Name"
                onChange={handleChange}
                name="firstName"
                value={formData.firstName}
                required
            />
            </label>
            <label className="formLastName" htmlFor="lastName">Last Name
            <input
                id="lastName"
                type="text"
                placeholder="Last Name"
                onChange={handleChange}
                name="lastName"
                value={formData.lastName}
                required
            />
            </label>
            <label className="formEmail" htmlFor="email">Email
            <input
                id="email"
                type="email"
                placeholder="Email"
                onChange={handleChange}
                name="email"
                value={formData.email}
                required
                autoComplete='off'
            />
            </label>
            <label className="formPhone" htmlFor="phone">Phone
            <input
                id="phone"
                type="tel"
                placeholder="Phone"
                onChange={handleChange}
                name="phone"
                value={formData.phone}
                required
                autoComplete='off'
                minLength='10'
                maxLength='10'
            />
            </label>
            <label className="formServices" htmlFor="services">
              Services
            <select 
                id="services" 
                value={formData.services}
                onChange={handleChange}
                name="services"
                required
            >
                <option value={""} disabled>Select an option</option>
                {serviceOptionsValue}
            </select>
            </label>
            <label className="formComments" htmlFor="comments">Comments
            <textarea 
                id="comments"
                value={formData.comments}
                placeholder="Any additional requirements"
                onChange={handleChange}
                name="comments"
            />
            </label>
            <br />
            <br />
            <button className='submitButton'>Submit</button>
        </form>
    </div>
    <Card sx={{ backgroundColor: 'whitesmoke' }} className='contact-card'>
        <CardContent>
        {isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        map={map}
        loading = {<div>Loading...</div>}
        options={
          {mapTypeControl: false,
            zoomControl: true,
            streetViewControl: false,
            fullscreenControl: true,
          }
        }
>
      <MarkerF position={center} />
      </GoogleMap>
  ) : <></>}
        <h5>Airtec Marketing</h5>
        <address><b> LG-23 Bansi trade center <br />
        M.G. Road, near Holkar stadium,
        Indore (M.P.), 452001, India</b></address>
        <p><b>Phone:</b> +91 9425400986 | +91 7974667142</p>
            <a href="mailto:airtec.khuzema@gmail.com">airtec.khuzema@gmail.com</a>
        </CardContent>
    </Card>
</div>
  )
}